import React from 'react';
import {Button, Col, Container, Form, Row} from "react-bootstrap";
import './RegisterComponent.scss'
import {toast} from "react-toastify";
import {Helmet} from "react-helmet";
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Watcher from '../Watcher/Watcher';
import watchers from './watchers.json';

interface IProps {
}

interface IState {
    name: string;
    pluginUrl: string;
    email: string;
    checked: boolean;
}
interface IWatcher {
    name: string;
    url: string;
    imgSrc: string;
};

export default class RegisterComponent extends React.Component <IProps, IState> {
    private watchers: IWatcher[];

    constructor(props: any) {

        super(props);
        this.state = {
            name: '',
            pluginUrl: '',
            email: '',
            checked: false
        };
        this.watchers = watchers;
        if (process.env.NODE_ENV === 'development') {
            this.state = {
                name: 'Andi',
                pluginUrl: 'https://www.pluginboutique.com/product/2-Effects/16-EQ/4949-FabFilter-Pro-Q-3Pro-Q3?gclid=Cj0KCQjwwJuVBhCAARIsAOPwGASE1Fx5-DpAJpDZqsB-nFTZapYUDKfcx0G7HGNRvz2HaGkRSJ3DucAaAiblEALw_wcB',
                email: 'demo@plugin-ping.com',
                checked: true
            };
        }
        // if (this && this.handleInputChange) this.handleInputChange = this.handleInputChange.bind(this);
        if (this && this.handleSubmit) this.handleSubmit = this.handleSubmit.bind(this);
    }

    resetURL = () => {
        this.setState({pluginUrl: ''});
    };


    handleInputChange = (event: React.FormEvent) => {
        if (event && event.target) {
            type formName = 'value' | 'name' | 'pluginUrl' | 'email';
            const target = (event.target as HTMLInputElement);
            const name = target.name as formName;
            const value = target.value;
            const checked = target.checked;

            if (name && value) {
                // TODO: find better way to solve this
                // @ts-ignore:next-line
                this.setState({
                    [name]: value
                });
            } else if (checked === true || checked === false) {
                this.setState({
                    'checked': checked
                });
            }
        }
    };

    handleSubmit = (event: any) => {
        const url = process.env.REACT_APP_SERVER_URL_PORT ? process.env.REACT_APP_SERVER_URL_PORT + '/watchers/' : 'http://localhost:3000/watchers/';
        const cleanUrl = url.replace(/([^:]\/)\/+/g, "$1");
        fetch(cleanUrl, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                name: this.state.name,
                pluginUrl: this.state.pluginUrl,
                email: this.state.email,
            })
        }).then(response => response.json())
            .then(data => {
                if (data.success === true) {
                    toast.success( 'We will notify you once we detect a price change. You can set a second plugin alert if you like.');
                } else {
                    if (data.statusCode === 400 && data.message) {
                        toast.error(data.message);
                    } else {
                        toast.error('Error contact support');
                    }
                }
                this.resetURL();
            });
        event.preventDefault();
    };

    render() {
        return (<div data-testid="RegisterComponent" className="RegisterComponent">
            <Helmet>
                <title>Plugin Alert</title>
                <meta property="og:url" content="https://plugin-ping.com" />
                <meta id="meta-description" name="description" content="Set a discount alert for your favorite plugin."/>
                <meta id="og-title" property="og:title" content="Plugin Ping"/>
                <meta id="og-image" property="og:image" content="https://plugin-ping.com/public/plugin-ping.jpg"/>
            </Helmet>
            <div className='background backgroundKim d-flex'>
                <Container className="my-auto">
                    <Row className="mx-0">
                        <Col xs lg="6">
                            <h1 className='TeaserText'>
                                KIM WILL NOTIFY YOU ONCE YOUR FAVORITE PLUGIN IS ON SALE
                            </h1>
                        </Col>
                    </Row>

                    <Row className="mx-0">
                        <Col xs lg="6">
                            <Form onSubmit={this.handleSubmit}>
                                <Form.Group className="mb-3" controlId="formName">
                                    <Form.Label>Name</Form.Label>
                                    <Form.Control type="text" name="name" required={true} minLength={3} placeholder="Enter Name" value={this.state.name} onChange={this.handleInputChange}/>
                                    <Form.Text className="text-muted">
                                        How can we call you?
                                    </Form.Text>
                                </Form.Group>

                                <Form.Group className="mb-3" controlId="formBasicEmail">
                                    <Form.Label>Email Address</Form.Label>
                                    <Form.Control type="email" name="email" required={true} placeholder="Enter email" value={this.state.email} onChange={this.handleInputChange}/>
                                    <Form.Text className="text-muted">
                                        We'll never share your email with anyone else. You get a notification once the price drops below the current one.
                                    </Form.Text>
                                </Form.Group>

                                <Form.Group className="mb-3" controlId="formBasicEmail">
                                    <Form.Label>Plugin URL</Form.Label>
                                    <Form.Control type="url" name="pluginUrl" required={true} placeholder="https://pluginboutique.com/xyz" value={this.state.pluginUrl} onChange={this.handleInputChange}/>
                                    <Form.Text className="text-muted">
                                        Enter the website URL where you can buy this plugin.
                                    </Form.Text>
                                </Form.Group>

                                <Form.Group className="mb-3" controlId="formBasicCheckbox">
                                    <Form.Check type="checkbox" label="I agree that I get an email notification once the price drops."  onChange={this.handleInputChange}/>
                                </Form.Group>
                                <Button variant="primary" className="btn-lg" type="submit" disabled={!this.state.checked}>
                                    Submit
                                </Button>
                            </Form>

                        </Col>
                    </Row>
                </Container>
            </div>
            <div className='background backgroundWhite d-flex'>
                <Container className="my-auto">
                    <div>
                        <h3>FAQ</h3>
                    </div>
                    <div className="my-3">
                        <Accordion>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                            >
                                <Typography>How does it work?</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Typography>
                                    <p>Just type in your name, email, and the URL of the website which sells the plugin. Once the plugin's price drops, we will send you an email notification. Check out this video to see how to use this website.</p>
                                    <div className="video-wrap">
                                        <div className="video-container">
                                            <iframe src="https://www.youtube.com/embed/B1jhDCgsxUY"
                                                    title="YouTube video player" frameBorder="0"
                                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                                    allowFullScreen></iframe>
                                        </div>
                                    </div>

                                </Typography>
                            </AccordionDetails>
                        </Accordion>
                        <Accordion>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel2a-content"
                                id="panel2a-header"
                            >
                                <Typography>Can I add Plugins Which are Already Discounted?</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Typography>
                                    Yes, but as we notify you on price drops its unlikely that the plugin sells for cheaper then the discounted price.
                                </Typography>
                            </AccordionDetails>
                        </Accordion>
                        <Accordion>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel2a-content"
                                id="panel2a-header"
                            >
                                <Typography>Can I track Plugins Which are Already Discounted?</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Typography>
                                    Yes, but as we notify you once the price drops it's unlikely that the plugin sells for cheaper than the discounted price.
                                </Typography>
                            </AccordionDetails>
                        </Accordion>
                        <Accordion>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel2a-content"
                                id="panel2a-header"
                            >
                                <Typography>Can All Plugin Sales Be Tracked?</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Typography>
                                    If the manufacturer or shop allows our service to read his website, we track the price for you. Some websites don't permit this. Fortunately, it is just a few.
                                </Typography>
                            </AccordionDetails>
                        </Accordion>
                        <Accordion>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel2a-content"
                                id="panel2a-header"
                            >
                                <Typography>What happens once the price drops?</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Typography>
                                    We will inform you via email once the plugin is on sale.
                                </Typography>
                            </AccordionDetails>
                        </Accordion>
                    </div>


                </Container>
            </div>
            <div className='background backgroundDark d-flex'>
                <Container className="my-auto">
                    <div>
                        <h3>Plugins Other People Watched</h3>
                    </div>
                    <div className="my-3 flex-container">

                        {
                            this.watchers.map(el => <div className="flex-item flex-3 p-3"><Watcher name={el.name} url={el.url} imgSrc={el.imgSrc}/></div>)
                        }
                    </div>


                </Container>
            </div>
        </div>)
    }
};

import React from 'react';
import './FloatingBannerComponent.scss';
import {getSaleInfo} from "../../services/sales/sales.service";

const FloatingBannerComponent = () => {
    const salesInfo = getSaleInfo();
    return (
        <div className="FloatingBannerComponent">
            <div className="floating-banner">
                {salesInfo.reason + ' ' + salesInfo.discount +'% OFF'}
            </div>
        </div>
    );
};

export default FloatingBannerComponent;

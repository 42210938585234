import Footer from "../components/Footer/Footer";
import RegisterComponent from "../components/RegisterComponent/RegisterComponent";

export default function PluginPingRoute() {
    return (
        <div>
            <RegisterComponent/>
            <Footer/>
        </div>

    );
}

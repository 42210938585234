import Footer from "../components/Footer/Footer";
import SqueezeTrackReviewComponent from "../components/SqueezeTrackReviewComponent/SqueezeTrackReviewComponent";

export default function SqueezeTrackReviewRoute() {
    return (
        <div>
            <SqueezeTrackReviewComponent/>
            <Footer/>
        </div>
    );
}

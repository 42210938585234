import salesData from './sales.json';

export function getSalesData() {
    return salesData;
}

export function getSaleInfo() {
    const currentDate = new Date().setHours(0, 0, 0, 0);


    for (const sale of salesData) {
        const startDate = new Date(sale.startDate).setHours(0, 0, 0, 0);
        const endDate = sale.endDate ? new Date(sale.endDate) : startDate;
        endDate.setHours(0, 0, 0, 0);
        if (currentDate >= startDate && currentDate <= endDate) {
            return sale;
        }
    }

    return null; // Return null if no sale is currently active
}

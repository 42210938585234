import React from 'react';
import {Col, Container, Row} from "react-bootstrap";
import Button from 'react-bootstrap/Button';
import './SqueezeFreeLecturesComponent.scss';
import { useForm, SubmitHandler } from "react-hook-form"
import {toast} from "react-toastify";
import Footer from "../Footer/Footer";
import { useTracking } from "react-tracking";
import {useNavigate} from "react-router-dom";

let loaded = 0;

const SqueezeFreeLecturesComponent = () => {
    const {trackEvent} = useTracking({page: 'FreeLectures'})

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm()

    const navigate = useNavigate();

    const trackLoad = () => {
        if (loaded === 0) {
            loaded++;
            trackEvent({ action: 'load' })
        }
    }

    const preSubmit = (event) =>  {
        event.preventDefault();
        handleSubmit(onSubmit)(event);
    }
    const onSubmit: SubmitHandler<Inputs> = (data, event) => {
        const url = process.env.REACT_APP_SERVER_URL_PORT ? process.env.REACT_APP_SERVER_URL_PORT + '/optins/' : 'http://localhost:3000/optins/';
        const cleanUrl = url.replace(/([^:]\/)\/+/g, "$1");

        fetch(cleanUrl, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                email: data.email,
                optIn: 'PsytrancePowerSystemFreeLecture',
            })
        }).then(response => response.json())
            .then(data => {
                if (data.success === true) {
                    toast.success( 'Successfully registered, you\'ll get notified!');
                    trackEvent({ action: 'conversion' });
                    navigate('/self-learner?option=2')
                } else {
                    if (data.statusCode === 400 && data.message) {
                        toast.error(data.message);
                    } else {
                        toast.error('Error contact support');
                    }
                }
            });
    }

    return (
        <div className={`squeezeFreeLectures`} data-testid="SqueezeFreeLecturesComponent">
            {
                trackLoad()
            }

            <Container>
                <Row>
                    <Col>
                        <div>
                            <h1 className="headline text-red"><b>
                                <span>PSYTRANCE<br/>RELEASE READY BLUEPRINT<br/>GET STARTED FREE<br/></span>
                            </b></h1>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <div>
                            <h2><b>
                                <span>Learn To Produce Psytrance People Will Love: Without 10 Years of Trial and Error</span>
                            </b></h2>
                        </div>
                    </Col>
                </Row>
                <Row className="teaser-image mt-2-sm mt-5">
                    <Col lg="5">
                        <div className="imageWrapper">
                            <img src={process.env.PUBLIC_URL + "/images/SqueezeWaitingList.webp"} alt="JoinTheWaitingList" title="JoinTheWaitingList"/>
                        </div>
                    </Col>
                    <Col lg="2" className="d-none d-lg-block">
                        <div className="imageWrapper">
                            <img src={process.env.PUBLIC_URL + "/images/ArrowGif.gif"} alt="JoinTheWaitingList" title="JoinTheWaitingList"/>
                        </div>
                    </Col>
                    <Col lg="5">
                        <div className="contactBox p-3" id="scroll-contact">
                            <h2>WATCH THE FIRST LECTURES FOR FREE!</h2>
                            <p>Enter your best email below and I'll send you access to the course material:</p>

                            <form onSubmit={preSubmit}>
                                {/* include validation with required or other standard HTML validation rules */}
                                {/*<input {...register("email", { isEmail: true, required: true })} />*/}

                                <div className="input-wrapper">
                                    {/*<label htmlFor="email">Email</label>*/}
                                    <input
                                        id="email"
                                        {...register("email", {
                                            required: "Email is required",
                                            validate: {
                                                maxLength: (v) =>
                                                    v.length <= 50 || "The email should have at most 50 characters",
                                                matchPattern: (v) =>
                                                    /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) ||
                                                    "Email address must be a valid address",
                                            },
                                        })}
                                    />
                                    {/*<div className="labelWrapper">*/}
                                    {/*    <label htmlFor="email">Email</label>*/}
                                    {/*</div>*/}


                                    {errors.email?.message && (
                                        <small>{errors.email.message}</small>
                                    )}
                                </div>


                                {/* errors will return when field validation fails  */}
                                {errors.exampleRequired && <span>This field is required and mus be a valid email</span>}
                                <button className="inputSubmit" type="submit">
                                    <img src={process.env.PUBLIC_URL + "/images/AccessButton.png"} width="80%" alt="submit" />
                                </button>
                            </form>

                            <p><em>By providing us with your information you are consenting to the collection and use of your information in accordance with our Privacy Policy.</em></p>
                        </div>
                    </Col>
                </Row>
                <Row className="mt-2-sm mt-5">
                    <Col>
                        <div>
                            <h2 className="secondHeadline">
                                <span>YES! I Want To Be To Make Music People Love Quickly</span>
                            </h2>
                        </div>
                    </Col>
                </Row>
            </Container>
            <Container className="reinforcementSection mt-1-sm mt-3" fluid>
                <Container className="">


                    <Row className="">
                        <Col>
                            <div className="reinforcementBox">
                                <Row className="mt-1-sm mt-3 bg-grey">
                                    <Col>
                                        <div>
                                            <h2 className="text-red">
                                                Get FREE access to the secrets which make my tracks chart - every time ;) <br/> Masterclass Reveals How To Produce <br/> Great Psytrance That People Love
                                            </h2>
                                        </div>
                                    </Col>
                                </Row>
                                <Row className="mt-1-sm mt-3 bg-grey">
                                    <Col>
                                        <div>
                                            <h3>
                                                Here's a taste of what you get in the Live (only the 1st time) Training
                                            </h3>
                                        </div>
                                    </Col>
                                </Row>
                                <Row className="mt-2 bg-grey">
                                    <Col>
                                        <ul>
                                            <li>Makes it easy to produce music that sounds good - Right Now!</li>
                                            <li>Prevent trial and error for years trying to look and feel great on every quest!</li>
                                            <li>How to produce music that people love - No experience required!</li>
                                            <li>Speedily produce a solid foundation for your music... even if you don't think you can</li>
                                            <li>Uncover the simple tools every Psytrance Producer can use to produce great music that people love</li>
                                            <li>Eliminate the stress about Psytrance Production - For All Eternity!</li>
                                        </ul>
                                    </Col>
                                </Row>
                                <Row className="mt-2 bg-grey">
                                    <Col>
                                        <div>
                                            <h4>
                                                Get access today to the first classes!
                                            </h4>
                                        </div>
                                    </Col>
                                </Row>
                                <Row className="mt-2 mb-3 bg-grey">
                                    <Col>
                                        <div>
                                            <a target="_self" href="#scroll-contact">
                                                <Button className="btn btn-ultra-lg">
                                                    YES! I'm Ready To Produce Music That People Love Now!
                                                </Button>
                                            </a>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        </Col>
                    </Row>

                </Container>
            </Container>
            <Footer/>
        </div>


    );

}



SqueezeFreeLecturesComponent.propTypes = {};

SqueezeFreeLecturesComponent.defaultProps = {};

export default SqueezeFreeLecturesComponent;

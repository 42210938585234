import React, { FC } from 'react';
import './VideoComponent.scss';

interface VideoComponentProps {
    videoId?: string;
    time?: string;
    autoplay?: string;
}

const VideoComponent: FC<VideoComponentProps> = ({ videoId, time , autoplay}) => {
    let formattedVideoURL = videoId ? `https://www.youtube.com/embed/${videoId}?rel=0&vq=hd1080` : '';
    if (autoplay) {
        formattedVideoURL += '&autoplay=1'
    }
    if (time) {
        formattedVideoURL += '&start=' + time;
    }
    return (
        <div className="VideoComponent" data-testid="VideoComponent">
            <div className="auto-resizable-iframe">
                <div>
                    <iframe
                        src={formattedVideoURL}
                        title="YouTube video player"
                        frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                        allowFullScreen
                    ></iframe>
                </div>
            </div>
        </div>
    );
};

export default VideoComponent;

import React from 'react';
import {Col, Container, Row} from "react-bootstrap";
import Button from 'react-bootstrap/Button';
import './SqueezeTrackReviewComponent.scss';
import { useForm, SubmitHandler } from "react-hook-form"
import {toast} from "react-toastify";
import { useTracking } from "react-tracking";

let loaded = 0;

const SqueezeTrackReviewComponent = () => {
    const {trackEvent} = useTracking({page: 'TrackReview'})

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm()

    const trackLoad = () => {
        if (loaded === 0) {
            loaded++;
            trackEvent({ action: 'load' })
        }
    }

    const preSubmit = (event) =>  {
        event.preventDefault();
        handleSubmit(onSubmit)(event);
    }
    const onSubmit: SubmitHandler<Inputs> = (data, event) => {
        const url = process.env.REACT_APP_SERVER_URL_PORT ? process.env.REACT_APP_SERVER_URL_PORT + '/optins/' : 'http://localhost:3000/optins/';
        const cleanUrl = url.replace(/([^:]\/)\/+/g, "$1");

        fetch(cleanUrl, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                email: data.email,
                optIn: 'TrackReview',
            })
        }).then(response => response.json())
            .then(data => {
                if (data.success === true) {
                    toast.success( 'Successfully registered, you\'ll get notified!');
                    trackEvent({ action: 'conversion' });
                } else {
                    if (data.statusCode === 400 && data.message) {
                        toast.error(data.message);
                    } else {
                        toast.error('Error contact support');
                    }
                }
            });
    }

    return (
        <div className={`squeezeTrackReview`} data-testid="SqueezeTrackReviewComponent">
            {
                trackLoad()
            }

            <Container>
                <Row>
                    <Col>
                        <div>
                            <h1 className="headline text-red"><b>
                                <span>LIVE TRACK REVIEW</span>
                            </b></h1>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <div>
                            <h2><b>
                                <span>LEVEL UP YOUR PRODUCTION SKILLS</span>
                            </b></h2>
                        </div>
                    </Col>
                </Row>
                <Row className="teaser-image mt-2-sm mt-5">
                    <Col lg="5">
                        <div className="imageWrapper">
                            <img src={process.env.PUBLIC_URL + "/images/track-review.jpeg"} alt="GetaTrackReview" title="GetaTrackReview"/>
                        </div>
                    </Col>
                    <Col lg="2 d-sm-none" className="d-none d-lg-block">
                        <div className="imageWrapper">
                            <img src={process.env.PUBLIC_URL + "/images/ArrowGif.gif"} alt="JoinTheWaitingList" title="JoinTheWaitingList"/>
                        </div>
                    </Col>
                    <Col lg="5">
                        <div className="contactBox p-3" id="scroll-contact">
                            <h2>GET ACCESS!</h2>
                            <p>Enter your best email below and I'll send you a FREE invitation to that special live session:</p>

                            <form onSubmit={preSubmit}>
                                {/* include validation with required or other standard HTML validation rules */}
                                {/*<input {...register("email", { isEmail: true, required: true })} />*/}

                                <div className="input-wrapper">
                                    {/*<label htmlFor="email">Email</label>*/}
                                    <input
                                        id="email"
                                        {...register("email", {
                                            required: "Email is required",
                                            validate: {
                                                maxLength: (v) =>
                                                    v.length <= 50 || "The email should have at most 50 characters",
                                                matchPattern: (v) =>
                                                    /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) ||
                                                    "Email address must be a valid address",
                                            },
                                        })}
                                    />
                                    {/*<div className="labelWrapper">*/}
                                    {/*    <label htmlFor="email">Email</label>*/}
                                    {/*</div>*/}


                                    {errors.email?.message && (
                                        <small>{errors.email.message}</small>
                                    )}
                                </div>


                                {/* errors will return when field validation fails  */}
                                {errors.exampleRequired && <span>This field is required and mus be a valid email</span>}
                                <button className="inputSubmit" type="submit">
                                    <img src={process.env.PUBLIC_URL + "/images/AccessButton.png"} width="80%" alt="submit" />
                                </button>
                            </form>

                            <p><em>By providing us with your information you are consenting to the collection and use of your information in accordance with our Privacy Policy.</em></p>
                        </div>
                    </Col>
                </Row>
                <Row className="mt-2-sm mt-5">
                    <Col>
                        <div>
                            <h2 className="secondHeadline">
                                <span>YES! I Want To Produce Music That Sounds Good Now!</span>
                            </h2>
                        </div>
                    </Col>
                </Row>
            </Container>
            <Container className="reinforcementSection mt-1-sm mt-3" fluid>
                <Container className="">


                        <Row className="">
                            <Col>
                                <div className="reinforcementBox">
                                    <Row className="mt-1-sm mt-3 bg-grey">
                                        <Col>
                                            <div>
                                                <h2 className="text-red">
                                                    Learn How You Can Produce Great Music <br/> Today - For FREE
                                                </h2>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row className="mt-1-sm mt-3 bg-grey">
                                        <Col>
                                            <div>
                                                <h3>
                                                    Here's a small sample of what you'll find in LIVE FREE Track Review:
                                                </h3>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row className="mt-2 bg-grey">
                                        <Col>
                                            <ul>
                                                <li>Makes it easy to produce music that sounds good - Right Now!</li>
                                                <li>Feedback from other participants of the LIVE STREAM</li>
                                                <li>Finding Quickly Leaks in Your Production, which would have taken you years to find out!</li>
                                                <li>Loads of fun during the LIVE-stream
                                                </li>
                                                <li>FREE exposure on my YouTube channel for your music
                                                </li>
                                                <li>Learn from other tracks too and get inspired​
                                                </li>
                                            </ul>
                                        </Col>
                                    </Row>
                                    <Row className="mt-2 bg-grey">
                                        <Col>
                                            <div>
                                                <h4>
                                                    Submit Your Track Today!
                                                </h4>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row className="mt-2 mb-3 bg-grey">
                                        <Col>
                                            <div>
                                                <a target="_self" href="#scroll-contact">
                                                    <Button className="btn btn-ultra-lg">
                                                        YES! I Want To Improve My Music Now!
                                                    </Button>
                                                </a>
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                            </Col>
                        </Row>

                </Container>
            </Container>
        </div>


    );

}



SqueezeTrackReviewComponent.propTypes = {};

SqueezeTrackReviewComponent.defaultProps = {};

export default SqueezeTrackReviewComponent;

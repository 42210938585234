import React, { FC } from 'react';
import './UpsellShirtComponent.scss';
import {Col, Container, Row} from "react-bootstrap";
import Button from "react-bootstrap/Button";
import {useTracking} from "react-tracking";
let loaded = 0;
interface UpsellShirtComponentProps {}

const UpsellShirtComponent: FC<UpsellShirtComponentProps> = () => {
    const {trackEvent} = useTracking({page: 'Shirt'});
    const trackLoad = () => {
        if (loaded === 0) {
            loaded++;
            // @ts-ignore
            trackEvent({ action: 'load' })
        }
    }
    trackLoad();
    return (
        <div className="UpsellShirtComponent" data-testid="UpsellShirtComponent">
          <Container>
              <Row className="mt-3">
                  <Col>
                      <div>
                          <h1 className="headline text-red"><b>
                              <span>Thank YOU</span>
                          </b></h1>
                          <h2 className="headline text-red"><b>
                              <span>for Enrolling Into the Waiting List <br/>and Buying my Pack!</span>
                          </b></h2>
                          <h2 className="headline"><b>
                              <span>Enjoy it and dig as deep as you can! <br/>Write down all your questions as we will talk in the course about it!</span>
                          </b></h2>
                      </div>
                  </Col>
              </Row>
              <Row>
                  <Col>
                      <div>
                          <p>
                              <span>BTW. If you want to get a Psychedelic T-Shirt Like Mine <b>20% OFF</b> only with this special code: "THXYOU20"</span>
                          </p>
                          <div className="imageWrapper">
                              <img src={process.env.PUBLIC_URL + "/images/Shirt.webp"} width="25%" alt="Albert Hoffman Shirt" />
                          </div>
                      </div>
                  </Col>
              </Row>
              <Row className="mt-3 mb-5">
                  <Col>
                          <a target="_blank"  rel="noreferrer"  href="https://www.shirtee.com/en/store/psiger">
                              <Button className="btn btn-ultra-lg">
                                  YES! I want to Get Mine!
                              </Button>
                          </a>
                  </Col>
              </Row>

          </Container>
        </div>
    )
};

export default UpsellShirtComponent;

import React, { FC } from 'react';
import styles from './Privacy.module.scss';
import {Col, Container, Row} from "react-bootstrap";

interface PrivacyProps {}

const Privacy: FC<PrivacyProps> = () => (
  <div className={styles.Privacy} data-testid="Privacy">
      <Container>
          <Row>
              <Col>
                  <p>Website Privacy Policy Template</p>

                  <p>Last modified: 06/23/2023</p>

                  <p>Introduction</p>


                  <p>
                      "Andreas Geißinger Company" is committed to lawful, fair, and transparent collection of your data. This policy governs data collection by us and our affiliates (collectively the “Company”) when you purchase or use our products, services, software, and website (collectively the “Services”). It was drafted to help you understand the types of information we collect from you, how we use it, as well as how we share, store and protect it.
                      If you do not agree with this policy, you should not use our Services. By accessing or using our Services, you agree to this policy, which may change from time to time to better reflect our practices and applicable laws. Your continued use after we make change(s) is deemed acceptance of those changes.
                  </p>

                  <p>Children Under the Age of 13</p>

                  <p>Our Services are not intended for minors (children under 13 years of age or equivalent depending on jurisdiction, (“Children”), and use of our Services by Children is strictly prohibited. We do not knowingly collect personally identifiable information from Children. If we learn we have collected or received personal information from Children without verification of parental consent, we will delete that information. If you believe we might have any information from or about Children, please contact us.</p>

                  <p>Types of Information We Collect</p>

                  <p>We collect information if you voluntarily provide it to us. For example, if you sign up for our Services, you might give us your name and email address. You might also give us data when you email us or give us feedback. In addition, you may submit information online through surveys, forms, portals, or other interactive activities on our website. details about transactions you carry out through our website. It is always your choice whether or not to provide personal data. Do not provide personal data unless you are authorized to do so.</p>
                  <p>We may collect the following information directly from you:</p>

                  <p>
                      information that may be personally identified, such as name, address, e-mail address, and other identifier by which you may be contacted online or offline ("personal information");
                      information that is about you but individually does not identify you; and/or
                      information about how you interact with our website, such as internet connection or the equipment you use to access the Services.
                  </p>

                  <p>This policy does not apply to third-party sites that may link to, or be accessible from, our site. We do not control these third parties' tracking technologies or how they may be used. Your interactions with these sites are governed by the third parties’ applicable privacy statements. If you have any questions about these sites, you should contact the responsible provider directly.
                  We may also collect information automatically:
                  As you interact with our website, we may use automatic data collection technologies to collect certain information about your equipment, browsing actions, and patterns, including: details of your visits to our website and information about your computer and internet connection. The information we collect automatically does not include personal information.
                  The technologies we use for this automatic data collection may include cookies. You may refuse to accept browser cookies by activating the appropriate setting on your browser, but if you do you may be unable to access certain parts of our website. Unless you have adjusted your browser setting so that it will refuse cookies, our system will issue cookies when you direct your browser to us.
                  </p>

                  <p>How We Use Your Information</p>

                  <p>We use information that we collect about you or that you provide to us, including personal information to present our Services to you; to provide you with information, products, or services that you request from us; to fulfill any other purpose for which you provide it; to provide you with notices about your account/ subscription, including expiration and renewal notices; to carry out our obligations and enforce our rights arising from any contracts entered into between you and us, including for billing and collection; to comply with legal obligations; or for any other purpose with your consent.
                  If you are an EU resident, we will collect and use your personal data only if we have one or more legal bases for doing so under the GDPR. This means we collect and use your personal data only where you have given your consent for one or more specific purposes; it is necessary for our legitimate interests (or those of a third party) and your interests and fundamental rights do not override those interests; it is necessary to protect the vital interests of you or another natural person; or it is necessary to comply with a legal obligation.</p>

                  <p>Who We Share Your Information With</p>
                  <p>
                         We may disclose aggregated information about our users, and information that does not identify any individual, without restriction.
                         We may disclose personal information that we collect or you provide as described in this policy to our team members, agents, subsidiaries and affiliates who have a business need to know; to contractors, service providers, and other third parties we use to support our business; to a buyer or other successor in the event of a merger, divestiture, restructuring, reorganization, dissolution, or other sale or transfer of some or all of the Company’s assets; to fulfill the purpose for which you provide it; for any other purpose disclosed by us when you provide the information; and/or with your consent.
                         We may also disclose your personal information: to comply with any court order, law, or legal process, including to respond to any government or regulatory request; and/or to enforce or apply our terms of use and other agreements, including for billing and collection purposes.
                         If we believe disclosure is necessary or appropriate to protect the rights, property, or safety of the Company, our customers, or others.
                  </p>
                  <p>How We Protect Your Personal Information</p>

                  <p>We may store your personal data in any region or in any country where we or our service providers have facilities. We implement reasonable processes and adhere to best practices in order to protect your Personal Information from accidental loss and from unauthorized access, use, alteration, and disclosure. Unfortunately, the transmission of information via the internet is not completely secure. We will do our best to protect your personal information, but we cannot guarantee the security of your personal information transmitted to our website. Any transmission of personal information is at your own risk. We are not responsible for circumvention of any privacy settings or security measures contained on the website. We will store your personal data only until it is no longer needed to fulfill the purpose(s) for which it was collected or as required or permitted by law; at that point it will be anonymized, deleted, or isolated.</p>

                  <p>Accessing and Correcting Your Information</p>

                  <p>You can request to access, correct or delete any personal information that you have provided to us by contacting us at psiger.records@gmail.com. We may not accommodate a request to change information if we believe the change would violate any law or legal requirement or cause the information to be incorrect.</p>

                  <p>How to Contact Us</p>

                  <p>To ask questions or comment about this privacy policy and our privacy practices, contact us at:</p>

                  <p>Andreas Geißinger
                  Schumannstr. 9, München
                  Bavaria, Germany 81679
                  psiger.records@gmail.com
                  +4917652477009
                      psiger.com
                  </p>
              </Col>
          </Row>
          <Row>
              <Col>
                  <h1>Datenschutz-Richtlinie</h1>

                  <p>Datum des Inkrafttretens: March 05, 2019</p>

                  <p>Eisbach Solutions UG (haftungsbeschränkt) ("wir", "uns", "unser" usw.) betreibt die Website https://360-disrupt.de (nachstehend als "Dienst" bezeichnet).</p>

                  <p>Diese Seite enthält Informationen zu der Art und Weise, auf welche wir personenbezogene Daten erfassen, nutzen und offenlegen, wenn Sie unseren Dienst nutzen, sowie zu den Optionen, die Ihnen im Zusammenhang mit diesen Daten zur Verfügung stehen.</p>

                  <p>Wir nutzen Ihre Daten zur Bereitstellung und Verbesserung unseres Dienstes. Durch Inanspruchnahme des Dienstes erklären Sie sich mit der Erfassung und Nutzung von Daten durch uns nach Maßgabe dieser Richtlinie einverstanden. Soweit in dieser Datenschutz-Richtlinie nicht jeweils etwas anderes angegeben ist, kommt den in dieser Datenschutz-Richtlinie vorkommenden Begriffen jeweils dieselbe Bedeutung zu, die diesen in unseren Allgemeinen Geschäftsbedingungen (Terms and Conditions) (abrufbar über die  https://360-disrupt.de) zugewiesen wurde.</p>

                  <h2>Begriffsbestimmungen</h2>
                  <ul>
                      <li>
                          <p><strong>Dienst</strong></p>
                          <p>Der Dienst ist die von dem Eisbach Solutions UG (haftungsbeschränkt) betriebene Website https://360-disrupt.de</p>
                      </li>
                      <li>
                          <p><strong>Personenbezogene Daten</strong></p>
                          <p>Personenbezogene Daten sind Daten, die sich auf eine lebende Person beziehen, welche anhand dieser Daten (bzw. anhand dieser Daten in Kombination mit weiteren Informationen, die sich bereits in unserem Besitz befinden oder mit Wahrscheinlichkeit in unseren Besitz gelangen werden) identifizierbar ist.</p>
                      </li>
                      <li>
                          <p><strong>Nutzungsdaten</strong></p>
                          <p>Nutzungsdaten sind Daten, die automatisch im Rahmen der Nutzung des Dienstes oder innerhalb der Dienstinfrastruktur selbst (beispielsweise für die Dauer eines Seitenbesuchs) erfasst werden.</p>
                      </li>
                      <li>
                          <p><strong>Cookies</strong></p>
                          <p>Cookies sind kleine Dateien, die auf Ihrem Gerät (Computer oder mobiles Endgerät) gespeichert werden.</p>
                      </li>
                      <li>
                          <p><strong>Datenverantwortlicher</strong></p>
                          <p>Der Datenverantwortliche ist eine natürliche oder juristische Person, die (entweder alleine oder zusammen mit weiteren Personen) die Zwecke sowie die Art und Weise festlegt, für bzw. auf welche personenbezogene Daten verarbeitet werden bzw. zu verarbeiten sind.</p>
                          <p>Für die Zwecke dieser Datenschutz-Richtlinie sind wir der Datenverantwortliche in Bezug auf Ihre personenbezogenen Daten.</p>
                      </li>
                      <li>
                          <p><strong>Datenverarbeiter (oder Leistungsanbieter)</strong></p>
                          <p>Ein Datenverarbeiter (oder Leistungsanbieter) ist eine natürliche oder juristische Person, welche die Daten im Namen des Datenverantwortlichen verarbeitet.</p>
                          <p>Wir können die Leistungen verschiedener Leistungsanbieter in Anspruch nehmen, um Ihre Daten auf effizientere Weise zu verarbeiten.</p>
                      </li>
                      <li>
                          <p><strong>Datensubjekt (oder Nutzer)</strong></p>
                          <p>Ein Datensubjekt ist eine lebende Einzelperson, die unseren Dienst in Anspruch nimmt und Gegenstand personenbezogener Daten ist.</p>
                      </li>
                  </ul>

                  <h2>Erfassung und Nutzung von Daten</h2>
                  <p>Wir erfassen verschiedene Arten von Daten für eine Reihe von Zwecken, um den Dienst, den wir Ihnen zur Verfügung stellen, zu verbessern.</p>

                  <h3>Arten der erfassten Daten</h3>

                  <h4>Personenbezogene Daten</h4>
                  <p>Im Rahmen der Nutzung unseres Dienstes bitten wir Sie gegebenenfalls um die Zurverfügungstellung bestimmter persönlich identifizierbarer Daten, die wir dazu nutzen, um Sie zu kontaktieren oder zu identifizieren ("personenbezogene Daten"). Persönlich identifizierbare Daten umfassen beispielsweise folgende Daten (sind jedoch nicht auf diese beschränkt):</p>

                  <ul>
                      <li>E-Mail-Adresse</li>    <li>Vorname und Nachname</li>    <li>Telefonnummer</li>        <li>Cookies und Nutzungsdaten</li>
                  </ul>

                  <p>Wir nutzen Ihre personenbezogenen Daten gegebenenfalls dafür, um Ihnen Newsletters, Marketing- oder Werbematerialien und sonstige Informationen zu übersenden, die für Sie von Interesse sein könnten. Sie können bestimmte oder alle unserer derartigen Mitteilungen deaktivieren, indem Sie auf den entsprechenden Deaktivierungslink klicken oder die diesbezüglichen Anweisungen in unseren E-Mails befolgen.</p>

                  <h4>Nutzungsdaten</h4>

                  <p>Wir können außerdem Daten zu der Art und Weise erfassen, auf welche auf unseren Dienst zugegriffen wird bzw. auf welche diese genutzt werden ("Nutzungsdaten"). Diese Nutzungsdaten umfassen gegebenenfalls die Internet-Protocol-Adresse (IP-Adresse) Ihres Computers, Ihren Browsertyp, Ihre Browserversion, die von Ihnen innerhalb unseres Dienstes besuchten Seiten, den Zeitpunkt und das Datum Ihres Besuchs, die Gesamtverweildauer auf den betreffenden Seiten, individuelle Geräteidentifikationsmerkmale und weitere Diagnostikdaten.</p>

                  <h4>Tracking & Cookies</h4>
                  <p>Wir setzen Cookies und ähnliche Tracking-Technologien zur Überwachung der Aktivität innerhalb unseres Dienstes ein und speichern in diesem Zusammenhang bestimmte Daten.</p>
                  <p>Cookies sind Dateien mit einem geringen Datenumfang, wie zum Beispiel anonyme einzigartige Identifikatoren. Cookies werden von einer Website an Ihren Browser gesendet und auf Ihrem Gerät gespeichert. Die sonstigen von uns eingesetzten Tracking-Technologien sind so genannte Beacons, Tags und Scripts und dienen der Erfassung und Nachverfolgung von Daten sowie der Verbesserung und Analyse unseres Dienstes.</p>
                  <p>Sie können in den Einstellungen Ihres Browsers bestimmen, ob Sie alle Cookies ablehnen oder nur bestimmte Cookies akzeptieren möchten. Falls Sie jedoch die Annahme von Cookies verweigern, können Sie gegebenenfalls Teile unseres Dienstes nicht in Anspruch nehmen.</p>
                  <p>Beispiele für von uns eingesetzte Cookies:</p>
                  <ul>
                      <li><strong>Sitzungs-Cookies.</strong> Wir setzen Sitzungs-Cookies für den Betrieb unseres Dienstes ein.</li>
                      <li><strong>Präferenz-Cookies.</strong> Wir setzen Präferenz-Cookies ein, um Ihre Präferenzen und verschiedenen Einstellungen zu speichern.</li>
                      <li><strong>Sicherheits-Cookies.</strong> Wir setzen Sicherheits-Cookies für Sicherheitszwecke ein.</li>
                  </ul>

                  <h2>Datennutzung</h2>
                  <p>Wir bei Eisbach Solutions UG (haftungsbeschränkt) nutzen die erfassten Daten für verschiedene Zwecke, beispielsweise um:</p>
                  <ul>
                      <li>Ihnen unseren Dienst zur Verfügung zu stellen und diesen aufrecht zu erhalten;</li>
                      <li>Ihnen Änderungen in Bezug auf unseren Dienst mitzuteilen;</li>
                      <li>es Ihnen auf Wunsch zu ermöglichen, an den interaktiven Teilen unseres Dienstes teilzunehmen;</li>
                      <li>Kundendienstleistungen zur Verfügung zu stellen;</li>
                      <li>Analysedaten und sonstige wertvolle Daten zu erfassen, damit wir unseren Dienst verbessern können;</li>
                      <li>die Nutzung unseres Dienstes zu überwachen;</li>
                      <li>technische Probleme zu erkennen, zu vermeiden und zu beheben;</li>
                      <li>Ihnen Neuigkeiten, Sonderangebote und allgemeine Informationen zu sonstigen von uns angebotenen Produkten, Dienstleistungen und Veranstaltungen mitzuteilen, soweit sie denjenigen ähneln, die Sie bereits von uns erworben oder bei uns angefragt haben und Sie solche Mitteilungen nicht deaktiviert haben.</li>
                  </ul>

                  <h2>Rechtliche Grundlage für die Verarbeitung personenbezogener Daten nach Maßgabe der Datenschutz-Grundverordnung (DSGVO)</h2>
                  <p>Falls sich Ihr Wohnsitz innerhalb des Europäischen Wirtschaftsraums (EWR) befindet, hängt die rechtliche Grundlage von Eisbach Solutions UG (haftungsbeschränkt) für die Erfassung und Nutzung der in dieser Datenschutz-Richtlinie beschriebenen personenbezogenen Daten von den von uns erfassten personenbezogenen Daten sowie von dem erfassungsspezifischen Kontext ab.</p>
                  <p>Wir bei Eisbach Solutions UG (haftungsbeschränkt) können Ihre personenbezogenen Daten aus folgenden Gründen verarbeiten:</p>
                  <ul>
                      <li>Wir müssen einen mit Ihnen eingegangen Vertrag erfüllen.</li>
                      <li>Sie haben uns hierzu Ihre Zustimmung erteilt.</li>
                      <li>Die Verarbeitung dient unseren legitimen Interessen, und diese sind Ihren Rechten gegenüber nicht nachrangig.</li>
                      <li>Die Verarbeitung dient Zahlungsverarbeitungszwecken.</li>    <li>Die Verarbeitung dient der Einhaltung der gesetzlichen Bestimmungen.</li>
                  </ul>

                  <h2>Aufbewahrung von Daten</h2>
                  <p>Eisbach Solutions UG (haftungsbeschränkt) wird Ihre personenbezogenen Daten nur so lange aufbewahren, wie dies für die Zwecke dieser Datenschutz-Richtlinie erforderlich ist. Wir werden Ihre personenbezogenen Daten in dem Umfang aufbewahren und nutzen, wie es für die Erfüllung unserer gesetzlichen Pflichten (falls wir Ihre Daten beispielsweise zur Einhaltung anwendbarer Gesetze aufbewahren müssen), zur Beilegung von Streitigkeiten sowie zur Durchsetzung unserer rechtlichen Vereinbarungen und Richtlinien erforderlich ist.</p>
                  <p>Eisbach Solutions UG (haftungsbeschränkt) wird außerdem Nutzungsdaten für interne Analysezwecke aufbewahren. Nutzungsdaten werden üblicherweise für kürzere Zeiträume aufbewahrt, soweit diese nicht für eine Verstärkung unserer Sicherheitsvorkehrungen oder eine Verbesserung der Funktionalität unseres Dienstes eingesetzt werden oder wir nicht gesetzlich zu einer längeren Aufbewahrung verpflichtet sind.</p>

                  <h2>Übertragung von Daten</h2>
                  <p>Ihre Daten, einschließlich personenbezogener Daten, können auf Computer übertragen – und auf solchen aufbewahrt – werden, die sich außerhalb Ihres Heimatstaates, Ihrer Heimatprovinz, Ihres Heimatlandes oder einer sonstigen Rechtsordnung befinden und somit Datenschutzgesetzen unterliegen, die sich von den Datenschutzgesetzen in Ihrer Rechtsordnung unterscheiden.</p>
                  <p>Falls Sie sich außerhalb von Germany befinden und sich dazu entscheiden, Daten an uns zu übermitteln, müssen Sie zur Kenntnis nehmen, dass wir Ihre Daten, einschließlich personenbezogener Daten, nach Germany übertragen und diese dort verarbeiten.</p>
                  <p>Ihre Zustimmung zu dieser Datenschutz-Richtlinie und eine nachfolgende Übermittlung von Daten Ihrerseits stellt eine Einverständniserklärung Ihrerseits zu der genannten Übertragung dar.</p>
                  <p>Eisbach Solutions UG (haftungsbeschränkt) wird alle im zumutbaren Rahmen erforderlichen Schritte unternehmen um sicherzustellen, dass Ihre Daten auf sichere Weise sowie in Übereinstimmung mit dieser Datenschutz-Richtlinie behandelt werden, und dass Ihre personenbezogenen Daten nicht an Organisationen oder in Länder übertragen werden, hinsichtlich welcher keine hinreichenden Kontrollmechanismen in Bezug auf die Sicherheit Ihrer Daten und sonstigen personenbezogenen Informationen vorliegen.</p>

                  <h2>Offenlegung von Daten</h2>

                  <h3>Gesetzliche Anforderungen</h3>
                  <p>Eisbach Solutions UG (haftungsbeschränkt) kann Ihre personenbezogenen Daten unter Umständen offenlegen, wenn es unter Beachtung der Grundsätze von Treu und Glauben der Ansicht ist, dass dies zur Erreichung der nachfolgenden Zielsetzungen erforderlich ist:</p>
                  <ul>
                      <li>zur Erfüllung einer gesetzlichen Pflicht</li>
                      <li>zum Schutz und zur Verteidigung der Rechte oder des Eigentums von Eisbach Solutions UG (haftungsbeschränkt)</li>
                      <li>zur Vermeidung oder Untersuchung möglicher Fehlverhaltensweisen in Bezug auf den Dienst </li>
                      <li>zum Schutz der persönlichen Sicherheit der Nutzer des Dienstes oder der Öffentlichkeit</li>
                      <li>zur Vermeidung von Haftungsansprüchen</li>
                  </ul>

                  <h2>Datensicherheit</h2>
                  <p>Die Sicherheit Ihrer Daten ist uns wichtig. Bitte vergessen Sie jedoch nicht, dass es keine Übertragungsmethoden über das Internet und keine elektronischen Speichermedien gibt, die 100 % sicher sind. Obwohl wir stets bemüht sind, kommerziell annehmbare Maßnahmen zum Schutz Ihrer personenbezogenen Daten umzusetzen, können wir eine absolute Sicherheit nicht garantieren.</p>

                  <h2>"Do Not Track"-Signale</h2>
                  <p>"Do Not Track" ("DNT") wird von uns nicht unterstützt. DNT ist eine Einstellung, die Sie innerhalb Ihres Browsers vornehmen können und die Websites mitteilt, dass Sie keine Nachverfolgung wünschen.</p>
                  <p>Sie können die DNT-Funktion auf der "Präferenzen"- oder "Einstellungen"-Seite Ihres Browsers ein- oder abschalten.</p>

                  <h2>Ihre Datenschutzrechte im Rahmen der Datenschutz-Grundverordnung (DSGVO)</h2>
                  <p>Soweit sich Ihr Wohnsitz innerhalb des Europäischen Wirtschaftsraums (EWR) befindet, haben Sie bestimmte Datenschutzrechte. Eisbach Solutions UG (haftungsbeschränkt) ist bestrebt, zumutbare Maßnahmen umzusetzen, die es Ihnen erlauben, Ihre personenbezogenen Daten zu korrigieren, zu ändern, zu löschen oder deren Nutzung zu beschränken.</p>
                  <p>Falls Sie in Erfahrung bringen möchten, welche personenbezogenen Daten wir zu Ihnen gespeichert haben, und falls Sie eine Löschung Ihrer Daten aus unserem System wünschen, nehmen Sie bitte Kontakt zu uns auf.</p>
                  <p>Unter bestimmten Umständen haben Sie die folgenden Datenschutzrechte:</p>
                  <ul>
                      <li>
                          <p><strong>Das Recht auf Zugriff auf die Daten, die wir zu Ihnen gespeichert haben, sowie auf deren Aktualisierung bzw.</strong> Löschung. Soweit dies jeweils möglich ist, können Sie auf Ihre personenbezogenen Daten direkt in dem Abschnitt mit Ihren Account-Einstellungen zugreifen sowie diese aktualisieren bzw. um deren Löschung bitten. Falls es Ihnen nicht möglich ist, diese Handlungen selbst vorzunehmen, nehmen Sie bitte Kontakt zu uns auf, damit wir Ihnen behilflich sein können.</p>
                      </li>
                      <li>
                          <p><strong>Das Recht auf Berichtigung.</strong> Sie haben das Recht auf Berichtigung Ihrer Daten, falls diese unzutreffend oder unvollständig sein sollten.</p>
                      </li>
                      <li>
                          <p><strong>Das Recht auf Widerspruch.</strong> Sie haben das Recht, einer Verarbeitung Ihrer personenbezogenen Daten durch uns zu widersprechen.</p>
                      </li>
                      <li>
                          <p><strong>Das Recht auf Beschränkung.</strong> Sie haben das Recht, die Verarbeitung Ihrer personenbezogenen Daten durch uns zu beschränken.</p>
                      </li>
                      <li>
                          <p><strong>Das Recht auf Datenportabilität.</strong> Sie haben das Recht, von uns die Aushändigung einer Abschrift der Daten, die wir zu Ihnen gespeichert haben, in einem strukturierten, maschinenlesbaren und üblichen Format zu verlangen.</p>
                      </li>
                      <li>
                          <p><strong>Das Recht auf Zustimmungsrücknahme.</strong> Sie haben außerdem das Recht, Ihre Zustimmung in Fällen, in denen Eisbach Solutions UG (haftungsbeschränkt) rsich auf Ihre Zustimmung zur Verarbeitung Ihrer personenbezogenen Daten verlassen hat, jederzeit zurückzunehmen.</p>
                      </li>
                  </ul>
                  <p>Bitte nehmen Sie zur Kenntnis, dass wir vor einer Bearbeitung solcher Anfragen gegebenenfalls Ihre Identität überprüfen.</p>

                  <p>Sie haben das Recht, in Bezug auf die Erfassung und Nutzung Ihrer personenbezogenen Daten eine Beschwerde bei einer Datenschutzbehörde einzulegen. Nähere Informationen in diesem Zusammenhang können Sie von der für Sie örtlich zuständigen Datenschutzbehörde innerhalb des Europäischen Wirtschaftsraums (EWR) erfragen.</p>

                  <h2>Leistungsanbieter</h2>
                  <p>Wir beauftragen gegebenenfalls dritte Unternehmen und Einzelpersonen ("Leistungsanbieter") mit Unterstützungsleistungen zum einfacheren Angebot unseres Dienstes, mit der Erbringung von Leistungen in unserem Namen, mit der Erbringung von mit unserem Dienst verbundenen Leistungen oder mit Unterstützungsleistungen zur Analyse der Art und Weise, auf die unser Dienst in Anspruch genommen wird.</p>
                  <p>Diese Dritten können auf Ihre personenbezogenen Daten nur in dem Umfang Zugriff nehmen, der für die Erfüllung der genannten Aufgaben in unserem Namen erforderlich ist, und dürfen diese für keine sonstigen Zwecke offenlegen oder nutzen.</p>

                  <h3>Analytik</h3>
                  <p>Wir beauftragen gegebenenfalls dritte Leistungsanbieter mit der Überwachung und Analyse der Nutzung unseres Dienstes.</p>
                  <ul>
                      <li>
                          <p><strong>Google Analytics</strong></p>
                          <p>Google Analytics ist ein von Google angebotener Web-Analytics-Dienst, der Zugriffe auf Websites nachverfolgt und meldet. Google nutzt die gewonnenen Daten zur Nachverfolgung und Überwachung der Nutzung unseres Dienstes. Diese Daten werden mit anderen Google-Diensten geteilt. Google kann die gewonnenen Daten zur Kontextualisierung und Personalisierung der Werbeanzeigen innerhalb seines eigenen Werbenetzwerks nutzen.</p>
                          <p>Sie können die Übertragung Ihrer Aktivität innerhalb unseres Dienstes an Google Analytics abschalten, indem Sie das Browser-Add-on zur Deaktivierung von Google Analytics installieren. Das Add-on verhindert eine Datenübertragung an Google Analytics zu Besuchen bzw. Aktivität über das JavaScript von Google Analytics (ga.js, analytics.js und dc.js).</p>                <p>Weitere Informationen zu den Datenschutzmaßnahmen von Google können Sie auf Googles Webseite zu seinen Datenschutzbestimmungen (Privacy Terms) einsehen: <a href="https://policies.google.com/privacy?hl=en" aria-label="Privacy policy google.">https://policies.google.com/privacy?hl=en</a></p>
                      </li>
                  </ul>

                  <h3>Zahlungen</h3>
                  <p>Wir stellen gegebenenfalls entgeltliche Produkte und/oder Dienstleistungen innerhalb unseres Dienstes zur Verfügung. In diesem Fall setzen wir dritte Zahlungsanbieter für die Zahlungsbearbeitung ein.</p>
                  <p>Wir werden Ihre Zahlungskartenangaben weder speichern noch erfassen. Diese Daten werden direkt den genannten dritten Zahlungsanbietern übermittelt, deren Nutzung Ihrer personenbezogenen Daten deren jeweiliger Datenschutz-Richtlinie (Privacy Policy) unterliegt. Die genannten Zahlungsanbieter halten sich an die Standards, die von PCI-DSS unter Verwaltung des PCI Security Standards Council erstellt wurden. Letzterer ist ein gemeinsames Projekt von Markennamen wie beispielsweise Visa, MasterCard, American Express und Discover. Die PCI-DSS-Anforderungen unterstützen eine sichere Handhabung von Zahlungsdaten.</p>
                  <p>Bei den von uns eingesetzten Zahlungsanbietern handelt es sich um:</p>
                  <ul>
                      <li>
                          <p><strong>Stripe</strong></p>
                          <p>Die Datenschutz-Richtlinie (Privacy Policy) von Stripe können Sie hier einsehen <a href="https://stripe.com/us/privacy" aria-label="Privacy policy stripe.">https://stripe.com/us/privacy</a></p>
                      </li>
                      <li>
                          <p><strong>PayPal / Braintree</strong></p>
                          <p>Die Datenschutz-Richtlinie (Privacy Policy) von PayPal / Braintree können Sie hier einsehen <a href="https://www.paypal.com/webapps/mpp/ua/privacy-full" aria-label="Privacy policy paypal.">https://www.paypal.com/webapps/mpp/ua/privacy-full</a></p>
                      </li>
                  </ul>

                  <h2>Links zu anderen Websites</h2>
                  <p>Unser Dienst kann Links zu anderen Websites enthalten, die nicht von uns betrieben werden. Wenn Sie auf einen Drittlink klicken, werden Sie direkt auf die Website des betreffenden Dritten weitergeleitet. Wir empfehlen Ihnen dringend, sich jeweils die Datenschutz-Richtlinien aller von Ihnen besuchten Websites durchzulesen.</p>
                  <p>Wir haben keine Kontrolle über die Inhalte, Datenschutzvorschriften und -praktiken dritter Websites oder Dienste und übernehmen in diesem Zusammenhang keine Haftung.</p>

                  <h2>Privatsphäre Minderjähriger</h2>
                  <p>Unser Dienst richtet sich nicht an Personen, die das 18. Lebensjahr noch nicht vollendet haben ("minderjährige Personen").</p>
                  <p>Wir erfassen wissentlich keine persönlich identifizierbaren Daten zu minderjährigen Personen. Falls Sie ein Elternteil oder Vormund sind und es Ihnen bekannt wird, dass eine Ihrer Aufsicht unterstehende minderjährige Person uns personenbezogene Daten übermittelt hat, bitten wir Sie, mit uns Kontakt aufzunehmen. Falls uns bekannt wird, dass wir personenbezogene Daten einer minderjährigen Person ohne elterliche Zustimmung erfasst haben, setzen wir Maßnahmen zur Entfernung dieser Daten von unseren Servern um.</p>

                  <h2>Änderungen dieser Datenschutz-Richtlinie</h2>
                  <p>Wir können unsere Datenschutz-Richtlinie von Zeit zu Zeit aktualisieren. Jegliche solcher Änderungen teilen wir Ihnen mit, indem wir die aktualisierte Fassung auf dieser Seite veröffentlichen.</p>
                  <p>Wir werden Sie vor dem Inkrafttreten der betreffenden Änderung per E-Mail und/oder mittels einer sonstigen sichtbaren Mitteilung innerhalb unseres Dienstes informieren und das "Datum des Inkrafttretens" am Beginn dieser Datenschutz-Richtlinie aktualisieren.</p>
                  <p>Wir empfehlen Ihnen, diese Datenschutz-Richtlinie regelmäßig auf Änderungen hin durchzusehen. Änderungen dieser Datenschutz-Richtlinie werden im Zeitpunkt ihrer Veröffentlichung auf dieser Seite wirksam.</p>

                  <h2>Kontaktaufnahme</h2>
                  <p>Falls Sie Fragen zu dieser Datenschutz-Richtlinie haben, können Sie wie folgt Kontakt zu uns aufnehmen:</p>
                  <ul>
                      <li>Per E-Mail: help@360-disrupt.de</li>
                  </ul>
              </Col>
          </Row>
      </Container>


  </div>
);

export default Privacy;

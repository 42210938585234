import React, { FC } from 'react';
import './Watcher.scss';

interface WatcherProps {
    name: string;
    url: string;
    imgSrc: string;
}

const Watcher: FC<WatcherProps> = ({ name, url, imgSrc }) => (
    <a href={url} type="button"><div data-testid="Watcher">
      <div className="image-wrapper">
          <img src={process.env.PUBLIC_URL + imgSrc} alt={name} title={name}/>
      </div>
      <p className="my-2">{name}</p>
  </div></a>
);

export default Watcher;

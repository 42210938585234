import Footer from "../components/Footer/Footer";
import UpsellShirtComponent from "../components/UpsellShirtComponent/UpsellShirtComponent";

export default function UpsellShirtRoute() {
    return (
        <div>
            <UpsellShirtComponent/>
            <Footer/>
        </div>
    );
}

import Footer from "../components/Footer/Footer";
import UpsellSamuraiComponent from "../components/UpsellSamuraiComponent/UpsellSamuraiComponent";

export default function UpsellSamuraiRoute() {
    return (
        <div>
            <UpsellSamuraiComponent/>
            <Footer/>
        </div>
    );
}

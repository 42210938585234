import React from 'react';
import './EnrollButtonComponent.scss';



const EnrollButtonComponent = ({price}) => {

    const paymentLinks = {
        '99': 'https://buy.stripe.com/cN214igvKfDPc366oy',
        '149': 'https://buy.stripe.com/9AQ8wK93ibnz9UYaEN'
    };
    console.log('price',price)
    return (
        <div className="EnrollButtonComponent" data-testid="EnrollButtonComponent">
            <a href={paymentLinks[price.toString()]} target={'self'}>
                <button className="" type="button">
                    <img src={process.env.PUBLIC_URL + "/images/enroll/enroll" + price + ".png"} width="80%" alt="submit"/>
                </button>
            </a>
        </div>
    );
}

export default EnrollButtonComponent;

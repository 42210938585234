import React from 'react';
import './index.css';
import reportWebVitals from './reportWebVitals';
import { createRoot } from 'react-dom/client';
import track from "react-tracking";

import {
    BrowserRouter,
    Routes,
    Route,
} from "react-router-dom";
import Privacy from "./routes/privacy";
import NotFound from "./components/NotFound/NotFound";
import SqueezeWaitingListComponent from "./components/SqueezeWaitingListComponent/SqueezeWaitingListComponent";
import {ToastContainer} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import UpsellSamuraiRoute from "./routes/upsell-samurai";
import UpsellShirtRoute from "./routes/upsell-shirt";
import SqueezeTrackReviewRoute from "./routes/track-review-route";
import ScrollToTop from "./helpers/ScrollToTop";
import PluginPingRoute from "./routes/plugin-ping-route";
import UpsellSelfLearnerRoute from "./routes/upsell-self-learner-route";
import SqueezeMiniBankRoute from "./routes/mini-bank-route";
import SqueezeFreeLecturesComponent from "./components/SqueezeFreeLecturesComponent/SqueezeFreeLecturesComponent";

const container = document.getElementById('root');
const root = createRoot(container!);

const App = () => (
    <div>
        <BrowserRouter>
            <ScrollToTop />
            <Routes>
                <Route path="/" element={<SqueezeFreeLecturesComponent />} />
                <Route path="join-the-waiting-list" element={<SqueezeWaitingListComponent />} />
                <Route path="join-the-waitinglist" element={<SqueezeWaitingListComponent />} /> // keep for old links!
                <Route path="privacy" element={<Privacy />} />
                {/* eslint-disable-next-line react/jsx-no-undef */}
                <Route path="mini-bank" element={<SqueezeMiniBankRoute />} />
                <Route path="track-review" element={<SqueezeTrackReviewRoute />} />
                <Route path="thank-you-samurai" element={<UpsellSamuraiRoute />} />
                <Route path="thank-you-shirt" element={<UpsellShirtRoute />} />
                <Route path="self-learner" element={<UpsellSelfLearnerRoute />} />
                <Route path="plugin-ping" element={<PluginPingRoute />} />
                <Route path='*' element={<NotFound />}/>
            </Routes>
        </BrowserRouter>
        <ToastContainer />
    </div>

);

// functional component usage
const TrackedApp = track(
    // app-level tracking data
    { app: "psiger-app" },

    // top-level options
    {
        // custom dispatch to console.log in addition to pushing to dataLayer[]
        dispatch: data => {
            console.log(data);
            const url = process.env.REACT_APP_SERVER_URL_PORT ? process.env.REACT_APP_SERVER_URL_PORT + '/stats/' : 'http://localhost:3000/stats/';
            const cleanUrl = url.replace(/([^:]\/)\/+/g, "$1");
            fetch(cleanUrl, {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(data)
            }).then(response => response.json())
                .then(data => {
                    console.log("data", data)
                }).catch(rejected => {
                console.log("rejected",rejected);
            });
        }
    }
)(App);

root.render(<TrackedApp />);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

import Footer from "../components/Footer/Footer";
import SqueezeMiniBankComponent from "../components/SqueezeMiniBankComponent/SqueezeMiniBankComponent";

export default function SqueezeMiniBankRoute() {
    return (
        <div>
            <SqueezeMiniBankComponent/>
            <Footer/>
        </div>
    );
}

import Footer from "../components/Footer/Footer";
import UpsellSelfLearnerComponent from "../components/UpsellSelfLearnerComponent/UpsellSelfLearnerComponent";

export default function UpsellSelfLearnerRoute() {
    return (
        <div>
            <UpsellSelfLearnerComponent/>
            <Footer/>
        </div>
    );
}

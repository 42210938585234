import React, { FC } from 'react';
import styles from './Footer.module.scss';
import {Col, Container, Row} from "react-bootstrap";
import { Link } from "react-router-dom";


interface FooterProps {}

const Footer: FC<FooterProps> = () => (
  <div className={styles.Footer} data-testid="Footer">
    <Container>
        <Row>
            <Col className="my-2">
                <p>©{(new Date().getFullYear())} Psiger. All rights reserved. All trademarks shown belong to their owners.</p>
                <p><Link to="/privacy" target="_blank">Imprint and Privacy</Link></p>
            </Col>
        </Row>
    </Container>
  </div>
);

export default Footer;

import React from 'react';
import {Col, Container, Row} from "react-bootstrap";
import './UpsellSelfLearnerComponent.scss';
import {useTracking} from "react-tracking";
import VideoComponent from "../VideoComponent/VideoComponent";
import EnrollButtonComponent from "../EnrollButtonComponent/EnrollButtonComponent";
import FloatingBannerComponent from "../FloatingBannerComponent/FloatingBannerComponent";
import {getSaleInfo} from "../../services/sales/sales.service";
import {useSearchParams} from "react-router-dom";

let loaded = 0;
const OPTION_NO_TOP = "-1";
const OPTION_SQUEEZEMINIBANK = "1";


const UpsellSelfLearnerComponent= () => {

    const [searchParams] = useSearchParams();
    let option = searchParams.get('option')

    const {trackEvent} = useTracking({page: 'SelfLearner'});
    const trackLoad = () => {
        if (loaded === 0) {
            loaded++;
            trackEvent({ action: 'load' })
        }
    }

    const sale = getSaleInfo()
    const price = 99; // euros
    let discount = 30; // percent

    if (sale) {
        discount = sale.discount;
    }

    let header;
    let headerSub;
    let waitingList;

    console.log(option, "option");
    if(!option) {
        header = (
            <Container className={"center"}>
                <Row className="mt-1-sm mt-3">
                    <Col>
                        <div>
                            <h2 className="headline text-red"><b>
                                <span>AMAZING YOU ARE ON THE WAITING LIST</span>
                            </b></h2>
                            <h1 className="headline text-red"><b>
                                <span>BUT WAIT!</span>
                            </b></h1>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <div>
                            <p>
                                <span>Make sure to check your email (& spam folder) and whitelist psiger.records@gmail.com!</span>
                            </p>
                        </div>
                    </Col>
                </Row>
            </Container>
        )
        headerSub = (
            <Row className="mt-1-sm mt-3">
                <Col>
                    <div className="p-3" id="scroll-contact">
                        <h2>YOU CANNOT WAIT <br/>TO GET STARTED?</h2>
                        <h3>I got an amazing <b>One Time</b> Offer for You</h3>
                        <em>Once you close this site it's gone!</em>

                        <div className="imageWrapper mt-2">
                            <VideoComponent videoId={'t-p3SOdPCDY'} autoplay={true}></VideoComponent>
                        </div>
                    </div>
                </Col>
            </Row>
        )
        waitingList= (
            <h1 className={'text-white'}>You can skip the waiting list and already start with the course today!</h1>
        )
    }
    else if (option.toString() === OPTION_SQUEEZEMINIBANK) {
            header = (
                <Container className={"center"}>
                    <Row className="mt-1-sm mt-3">
                        <Col>
                            <div>
                                <h2 className="headline text-red"><b>
                                    <span>AMAZING I WILL SEND YOUR GIFT SOON</span>
                                </b></h2>
                                <h1 className="headline text-red"><b>
                                    <span>WAIT!</span>
                                </b></h1>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <div>
                                <p>
                                    <span>Make sure to check your email (& spam folder) and whitelist psiger.records@gmail.com!</span>
                                </p>
                            </div>
                        </Col>
                    </Row>
                </Container>
            )
            headerSub = (
                <Row className="mt-1-sm mt-3">
                    <Col>
                        <div className="p-3" id="scroll-contact">
                            <h2>YOU WANT TO LEARN HOW TO MAKE RELEASE READY MUSIC?</h2>
                            <h3>I got an amazing <b>One Time</b> Offer for You</h3>
                            <em>Once you close this site it's gone!</em>

                            <div className="imageWrapper mt-2">
                                <VideoComponent videoId={'t-p3SOdPCDY'} autoplay={true}></VideoComponent>
                            </div>
                        </div>
                    </Col>
                </Row>
            )
            waitingList = (
                <h1 className={'text-white'}>Do you want learn how to produce top-notch music?</h1>
            )
        }
    else if (option.toString() === OPTION_NO_TOP) {
        header = (
            <div></div>
        )
        headerSub = (
            <Row className="mt-1-sm mt-3">
                <Col>
                    <div className="p-3" id="scroll-contact">
                        <h2>YOU WANT TO LEARN HOW TO MAKE RELEASE READY MUSIC?</h2>
                        <h3>I got an amazing <b>One Time</b> Offer for You</h3>
                        <em>Once you close this site it's gone!</em>

                        <div className="imageWrapper mt-2">
                            <VideoComponent videoId={'t-p3SOdPCDY'} autoplay={true}></VideoComponent>
                        </div>
                    </div>
                </Col>
            </Row>
        )
        waitingList = (
            <h1 className={'text-white'}>Do you want learn how to produce top-notch music?</h1>
        )
    }
    else {
        header = (
            <Container className={"center"}>
                <Row className="mt-1-sm mt-3">
                    <Col>
                        <div>
                            <h2 className="headline text-red"><b>
                                <span>AMAZING I WILL SEND YOU THE FREE LECTURES SOON</span>
                            </b></h2>
                            <h1 className="headline text-red"><b>
                                <span>WAIT!</span>
                            </b></h1>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <div>
                            <p>
                                <span>Make sure to check your email (& spam folder) and whitelist psiger.records@gmail.com!</span>
                            </p>
                        </div>
                    </Col>
                </Row>
            </Container>
        )
        headerSub = (
            <Row className="mt-1-sm mt-3">
                <Col>
                    <div className="p-3" id="scroll-contact">
                        <h2>YOU WANT TO LEARN HOW TO MAKE RELEASE READY MUSIC?</h2>
                        <h3>I got an amazing <b>One Time</b> Offer for You</h3>
                        <em>Once you close this site it's gone!</em>

                        <div className="imageWrapper mt-2">
                            <VideoComponent videoId={'t-p3SOdPCDY'} autoplay={true}></VideoComponent>
                        </div>
                    </div>
                </Col>
            </Row>
        )
        waitingList = (
            <h1 className={'text-white'}>Do you want learn how to produce top-notch music?</h1>
        )
    }

    const check = <span
        className="svg-icon"><svg
        viewBox="0 0 24 24" fill="none" stroke="currentColor"
        stroke-width="2" stroke-linecap="round"
        stroke-linejoin="round" xmlns="http://www.w3.org/2000/svg"
        aria-hidden="true"><polyline
        points="20 6 9 17 4 12"></polyline></svg></span>

    return (
        <div className="UpsellSelfLearnerComponent" data-testid="UpsellSelfLearnerComponent">
            {sale && <FloatingBannerComponent />}
            {trackLoad()}
            {header}
            <Container className="upsell-offer center" fluid>
                <Container>
                    {headerSub}
                    <Row className="mt-2">
                        <Col>

                            <section className={'mt-1-sm mt-3'}>
                                {waitingList}

                                <Row className={'border-box-blue margin-top-150 pt-3 pb-3'}>
                                    <Col sm md={4} className={'packaging'}>
                                        <img className="offer-box" alt={'psytrance release blueprint packaging'} src={process.env.PUBLIC_URL + "/images/prb packaging design.png"}/>
                                    </Col>
                                    <Col className={'btn-offer-wrapper'}>
                                        <h2 className={'text-blue'}>Psytrance Release Blueprint</h2>
                                        <EnrollButtonComponent price={price}></EnrollButtonComponent>
                                    </Col>
                                </Row>
                            </section>

                            <section className={'mt-2-sm mt-5'}>
                                <h1>THIS COURSE</h1>
                                <h2>WILL CHANGE </h2>
                                <h1>YOUR PRODUCTION FOREVER</h1>

                                <div className={'mt-2-sm mt-5'}>
                                    <p>When I started out with making Psytrance my sound was incredibly bad, I had never made any music before, I didn't have any sense of rhythm or melody, and no clue at all what to do. Sounds great? No it sounded horrible as f*** but I believed it sounded well. A few years later after studying our art a lot with courses and mentors I know exactly what I did wrong. </p>
                                </div>

                                <div className={'mt-1-sm mt-3'}>
                                    <p>I'm now charting top positions and that's why I want to help you on your journey. This course will transform the way you make music, and how your music will sound dramatically. <b>You will learn how to produce a release-ready track that sounds insanely good. This track will be the blueprint for your future music career (or hobby).</b></p>
                                </div>
                            </section>

                            <section className={'mt-2-sm mt-5'}>
                                <h1>WHAT WILL YOU LEARN</h1>
                                <h2>INSIDE OF THE</h2>
                                <h1>PSYTRANCE RELEASE BLUEPRINT?</h1>

                                <section className={'module mt-2-sm mt-3 p-3'}>
                                    <ol>
                                        <li>Kick & Bass</li>
                                        <li>Drums</li>
                                        <li>Music Theory Basics, Creative Techniques, Melodic Leads</li>
                                        <li>Rhythm Theory, Rhythmic Leads, Pads, Atmos, FX</li>
                                        <li>Arrangement, Transitions, Drops, Intros, Breaks</li>
                                        <li>Mixing & Mastering</li>
                                        <li>Cover Art, Self Release, Label Release, Promotion</li>
                                        <li>Remixes, Collabs, Referencing, Using the blueprint, Finding Your Style</li>
                                    </ol>
                                    <p><i className={"text-small"}>The course runs for 8 months, each month contains one of the modules listed above.</i></p>
                                    <p>Each topic is explained in detail, so you can improve your production! Most masterclasses just fly over all the topics briefly.</p>

                                    <div className={"image-wrapper preview mb-5"}>
                                        <img alt={'preview'} src={process.env.PUBLIC_URL + "/images/benefits/preview.gif"}/>
                                    </div>

                                    <div className={'btn-offer-wrapper btn-offer-wrapper-small'}>
                                        <EnrollButtonComponent price={price}></EnrollButtonComponent>
                                    </div>
                                </section>
                            </section>

                            <section className={'mt-1-sm mt-3 mb-5'}>
                                <h1>WHAT CAN YOU GET TODAY?</h1>

                                <div className={'benefit benefit-video border-box-blue mt-1-sm mt-3 p-3'}>
                                    <Row>
                                        <Col sm md={6} className={'mt-1-sm mt-3'}>
                                            <h2 className={'benefit-header'}>Video lectures & Info material</h2>
                                            <p className={'benefit-text'}>Learn in your own pace: rewatch the material as often and whenever you like. You have unlimited access to all the videos for the rest of your life.</p>
                                            <p className={'price-tag'}>worth 500€</p>
                                        </Col>
                                        <Col sm md={6} >
                                            <div className={'image-wrapper'}>
                                                <img alt={'benefits'} src={process.env.PUBLIC_URL + "/images/benefits/YT.png"}/>
                                            </div>
                                        </Col>
                                    </Row>
                                </div>

                                <div className={'benefit benefit-presets margin-top-150 border-box-blue p-3'}>
                                    <Row>
                                        <Col sm md={6}>
                                            <div className={'image-wrapper'}>
                                                <img alt={'templates'} src={process.env.PUBLIC_URL + "/images/benefits/templates.png"}/>
                                            </div>
                                        </Col>
                                        <Col sm md={6} className={'mt-2-sm mt-5'}>
                                            <h2 className={'benefit-header'}>Presets, Templates, Samples</h2>
                                            <p className={'benefit-text'}>Don't start from zero, and compare whenver you are stuck. You will get tons of templates, presets and samples.</p>
                                            <p className={'price-tag'}>worth 150€</p>
                                        </Col>
                                    </Row>
                                </div>

                                <div className={'benefit margin-top-150 border-box-blue p-3'}>
                                    <Row>
                                        <Col sm md={6}>
                                            <h2 className={'benefit-header'}>EDU Discounts</h2>
                                            <p className={'benefit-text'}>Save money with our amazing EDU discounts.</p>
                                            <p className={'price-tag'}>worth 1000€</p>
                                        </Col>
                                        <Col sm md={6}>
                                            <div className={'image-wrapper'}>
                                                <img alt={'edu discounts'} src={process.env.PUBLIC_URL + "/images/benefits/edu.png"}/>
                                            </div>
                                        </Col>
                                    </Row>
                                </div>

                            </section>
                            <section className={'mt-1-sm mt-3'}>
                                    <h1 className={'text-pink'}>PREMIUM FEATURES YOU CAN ADD LATER</h1>
                                    <p className={'text-green'}>You only pay the difference once you upgrade - nothing is lost if you decide today!</p>

                                    <div className={"benefits-comparison"}>
                                        <Row>
                                            <Col md="6" className="box">
                                                <div>
                                                    <h3>Self Learner Course</h3>
                                                    <ul>
                                                        <li>{check}<span className={"icon-text"}>In depth video lectures</span></li>
                                                        <li>{check}<span className={"icon-text"}>Whole production explained from A-Z</span></li>
                                                        <li>{check}<span className={"icon-text"}>A course guide in PDF explaining everything in the videos</span></li>
                                                    </ul>
                                                </div>
                                            </Col>
                                            <Col md="6" className="box">
                                                <div>
                                                    <h3>Premium Course</h3>
                                                    <ul>
                                                        <li>{check}<span className={"icon-text"}>In depth video lectures</span></li>
                                                        <li>{check}<span className={"icon-text"}>Whole production explained from A-Z</span></li>
                                                        <li>{check}<span className={"icon-text"}>A course guide in PDF explaining everything in the videos</span></li>
                                                        <li>{check}<span className={"icon-text"}>Detailed Homework Schedule</span></li>
                                                        <li>{check}<span className={"icon-text"}>Each week detailed personalized video feedback on your homework</span></li>
                                                        <li>{check}<span className={"icon-text"}>Get Your Questions answered in Discord</span></li>
                                                        <li>{check}<span className={"icon-text"}>Access all prior Q&A sessions and reviews from other students</span></li>
                                                        <li>{check}<span className={"icon-text"}>Connect with like minded producers</span></li>
                                                        <li>{check}<span className={"icon-text"}>Access to a huge alumni network after you completed the course</span></li>
                                                        <li>{check}<span className={"icon-text"}>Access to a weekly Q&A session on Zoom (LIVE)</span></li>
                                                        <li>{check}<span className={"icon-text"}>A final release in our VA (Various Artists EP)</span></li>
                                                        <li>{check}<span className={"icon-text"}>Your track as blueprint for future success</span></li>
                                                    </ul>
                                                    <div><em><i className={'text-small'}>The premium option has limited seats and is only granted after prior selection.</i></em></div>
                                                    <div><em><a target="_blank" href={"https://forms.gle/kMDNaYJrirBq7zW98"}><button className={'btn btn-large btn-blue'}>Apply to Waitinglist</button></a></em></div>
                                                </div>

                                            </Col>
                                        </Row>

                                    </div>

                                <div className={'btn-offer-wrapper btn-offer-wrapper-small'}>
                                    <EnrollButtonComponent price={price}></EnrollButtonComponent>
                                </div>
                            </section>

                        </Col>
                    </Row>
                </Container>
            </Container>
            <Container className={"testimonials"} fluid>
                <Container>
                    <Row  className="mt-1-sm mt-3 center">
                        <Col>
                            <h1 className={'text-white'}>The course is brand new but read some testimonials from my Youtube channel</h1>
                            <div className={'mt-sm-1 mt-md-3 image-wrapper'}>
                                <img alt={'testimonials'} src={process.env.PUBLIC_URL + "/images/testimonials.png"}/>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                    <Col>
                        <div className="imageWrapper mt-4">
                            <h2>Or check out some results of my first students :)</h2>
                            <VideoComponent videoId={'FzKpUO9JkmQ'} time={'436'}></VideoComponent>
                        </div>
                    </Col>
                    </Row>
                </Container>
            </Container>

            <Container className={"general-info"} fluid>
                <Container>
                <Row>
                    <Col>
                        <section>
                            <hr/>
                            <h1>WHO, WHAT & WHEN</h1>
                            <ul>
                                <li>The course is made for beginner to intermediate, as long as you have touched your DAW (e.g. Ableton) and spend some hours in it you are good to go. You DON'T need to be an expert. If you have more experience we will make you rock solid fundamentals and a blazing fast workflow.</li>
                                <li>We will make a Full-On track 145A#.</li>
                                <li>The course will be in Ableton, we use as few external plugins as necessary, but a handful are used along the way especially for making K&B, and leads.</li>
                                <li>If you want to go premium: the next premium course starts 1st of March and it runs for eight months. But you can already start as a self learner.</li>
                            </ul>
                        </section>


                        <section className={'mt-2-sm mt-5'}>

                            <h1>HOW CAN I START TODAY?</h1>
                            <p>The course is divided into an educational part (a series of videos & materials) and a Discord forum in which you can ask production questions, submit your homework or network with peers.</p>
                            <p>You can already start today with the educational part and then decide later if you want to upgrade and benefit from the premium services.</p>
                            <p>The monthly fee you pay will be deducted once you decide to upgrade.</p>
                            <p>This is a special offer which you get  <b className="text-green">{discount}% off only on this site</b>. </p>
                            <div className={'btn-offer-wrapper btn-offer-wrapper-small'}>
                                <EnrollButtonComponent price={price}></EnrollButtonComponent>
                            </div>
                        </section>
                    </Col>
                </Row>
                <Row  className={'center'}>
                    <Col>

                        <div className={'offer-stack border-box-blue p-3'}>
                            <Row>
                                <Col>
                                    <div className={'image-offer-all image-wrapper'}>
                                        <img alt={'all benefits'} src={process.env.PUBLIC_URL + "/images/benefits/all.png"}/>
                                    </div>
                                </Col>
                            </Row>
                            <Row className={'mt-2-sm mt-5'}>
                                <Col>
                                    <h4>Video lectures & Info material <span className={'text-red'}>500€/month</span></h4>
                                    <h4>Presets, Templates, Samples <span className={'text-red'}>150€/month</span></h4>
                                    <h4>EDU Discounts <span className={'text-red'}>1000€</span></h4>
                                    <hr/>
                                </Col>
                            </Row>
                            <Row className={'mt-1'}>
                                <Col>
                                    <h2>➡️ <span className={'text-red'}>Total Value: 1650€</span> ⬅️</h2>
                                </Col>
                            </Row>
                            <Row className={'mt-1-sm mt-3'}>
                                <Col>
                                    <p>But You Will Get All Of This For The Low</p>
                                    <h3>ONE-TIME PRICE OF...</h3>
                                    <h1 className={'text-green'}>JUST {price}€/month</h1>
                                </Col>
                            </Row>
                            <Row className={'mt-1-sm mt-3'}>
                                <div className={'btn-offer-wrapper btn-offer-wrapper-small'}>
                                    <EnrollButtonComponent price={price}></EnrollButtonComponent>
                                </div>
                            </Row>
                        </div>


                    </Col>
                </Row>

                <Row className="secure mt-2-sm mt-5">
                    <Col sm md="6">
                        <Row>
                            <Col xs sm={6}>
                                <div className="imageWrapper">
                                    <img src={process.env.PUBLIC_URL + "/images/14-days-money-back.png"} width="80%" alt="Money Back Gurantee" />
                                </div>
                            </Col>
                            <Col xs sm={6}>
                                <h2>
                                    14 Day <br/>Guarantee
                                </h2>
                                <p>No question asked 14 day refund guaranteed. If you are unhappy for any reason, get your money back. Rock solid guarantee...</p>
                            </Col>
                        </Row>
                    </Col>
                    <Col sm md="6">
                        <Row>
                            <Col xs sm={6}>
                                <div className="imageWrapper">
                                    <img src={process.env.PUBLIC_URL + "/images/Shield.png"} width="80%" alt="Secure Payment" />
                                </div>
                            </Col>
                            <Col xs sm={6}>
                                <h2>
                                    Secure <br/>Payment
                                </h2>
                                <p>All orders are through a very secure network. Your credit card information is never stored in any way. We respect your privacy...</p>

                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Container>
            </Container>
        </div>
    )

}

export default UpsellSelfLearnerComponent;

import React from 'react';
import {Col, Container, Row} from "react-bootstrap";
import Button from "react-bootstrap/Button";
import './UpsellSamuraiComponent.scss';
import {useTracking} from "react-tracking";
let loaded = 0;

const UpsellSamuraiComponent = () => {

  const {trackEvent} = useTracking({page: 'Samurai'});
  const trackLoad = () => {
    if (loaded === 0) {
      loaded++;
      trackEvent({ action: 'load' })
    }
  }

  return (
      <div className="UpsellSamuraiComponent" data-testid="UpsellSamuraiComponent">
        {
          trackLoad()
        }
        <Container>
          <Row className="mt-1-sm mt-3">
            <Col>
              <div>
                <h2 className="headline text-red"><b>
                  <span>AMAZING THAT YOU ENROLLED</span>
                </b></h2>
                <h1 className="headline text-red"><b>
                  <span>BUT WAIT!</span>
                </b></h1>
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <div>
                <p>
                  <span>I notify you once we start make sure to check your email and whitelist this site!</span>
                </p>
              </div>
            </Col>
          </Row>
        </Container>
        <Container className="upsell-offer" fluid>
          <Container>
            <Row className="mt-1-sm mt-3">
              <Col>
                <div className="p-3" id="scroll-contact">
                  <h2>YOU CANNOT WAIT <br/>TO GET STARTED?</h2>
                  <h3>I got an amazing <b>One Time</b> Offer for You</h3>
                  <em>Once you close this site it's gone!</em>

                  <div className="imageWrapper mt-2">
                    <img src={process.env.PUBLIC_URL + "/images/145-Asharp-Samurai.jpg"} width="25%" alt="Samurai Cover" />
                  </div>
                </div>
              </Col>
            </Row>
            <Row className="mt-2">
              <Col>

                <p><b>Are you already hot for the course, but you cannot wait to learn?
                </b></p>

                <p>The first time I really investigated a pro-project from my mentor Lunatica I saw how all the elements relate to each other. I was able to dig really deep into things like how loud a certain elements, what properties does their sound have, how does the spectrum or the wave-form look like. And <b>I got a lot of valuable insights for my own production.</b> Way deeper then I got with experimenting on my own.</p>

                <p>I prepared something similar for you guys! Instead of a whole track I created a pack with a perfect K&b and drum groove for you!</p>

                <p>You will get a K&B in the same quality <b>I use them in my releases which charted top-ten multiple times.</b> You get all the audio files, that you can learn from it and try to recreate it. Soon you will learn in the course how to make a kick & bass and a groove - exactly like this one. <b>A powerful kick and bass made for chart-breakers.</b> If you are eager to explore or just want to make a track with a powerful solid foundation then you can get this one for <b className="text-red">50% off</b>. </p>

              </Col>
            </Row>
            <Row>
              <Col>

                {/*<a target="_self" href="https://buy.stripe.com/test_4gw3embyAaRj0QUdQQ">*/}
                {/*  <Button className="btn btn-lg">*/}
                {/*    YES! I want to get started right away! (TEST)*/}
                {/*  </Button>*/}
                {/*</a>*/}

                <a target="_self" href="https://buy.stripe.com/4gw6oCbbq9fr5EI8wz">
                  <Button className="mt-1-sm mt-3 mb-2 btn btn-ultra-lg">
                    YES! I want to get started right away!
                  </Button>
                </a>
              </Col>
            </Row>

            <Row className="secure mt-2-sm mt-5">
              <Col sm="12">
                <Row>
                  <Col>
                    <div className="imageWrapper">
                      <img src={process.env.PUBLIC_URL + "/images/30-days-money-back.png"} width="80%" alt="Money Back Gurantee" />
                    </div>
                  </Col>
                  <Col>
                    <h2>
                      30 Day <br/>Guarantee
                    </h2>
                    <p>No question asked 30 day refund guaranteed. If you are unhappy for any reason, get your money back. Rock solid guarantee...</p>
                  </Col>
                </Row>
              </Col>
              <Col sm="12">
                <Row>
                  <Col>
                    <div className="imageWrapper">
                      <img src={process.env.PUBLIC_URL + "/images/Shield.png"} width="80%" alt="Secure Payment" />
                    </div>
                  </Col>
                  <Col>
                    <h2>
                      Secure <br/>Payment
                    </h2>
                    <p>All orders are through a very secure network. Your credit card information is never stored in any way. We respect your privacy...</p>

                  </Col>
                </Row>
              </Col>
            </Row>
          </Container>
        </Container>
      </div>
  )

}

UpsellSamuraiComponent.propTypes = {};

UpsellSamuraiComponent.defaultProps = {};

export default UpsellSamuraiComponent;
